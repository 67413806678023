
import "../assets/bootstrap/css/bootstrap.min.css";
import "../assets/custom/css/main.css";
import "../assets/custom/css/structure.css";
import "../index.css";
import "../assets/custom/css/loader.css";
import "../assets/custom/css/authentication/auth_3.css";
import "../assets/custom/css/darkmode/global.css";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../dashboard";
import Register from "../register";
import Login from "../login"
import Resetpassword from "../resetpassword";
import TokenAuth from "../TokenAuth";
import ChangePassword from "../ChangePassword";
import Events from "../Events";

const App = () =>{

  return (
    
  <div>
    <Routes>      
      <Route exact path="/" element={<Dashboard />}/>
      <Route exact path="/login" element={<Login />}/>
      <Route exact path="/register" element={<Register />}/>
      <Route exact path="/resetpassword" element={<Resetpassword />}/>
      <Route exact path="/tokenauth/:token" element={<TokenAuth />}/>
      <Route exact path="/changepassword" element={<ChangePassword />}/>
      <Route exact path="/eventinfo" element={<Events />}/>
      <Route exact path="/eventinfo/:chart/:value" element={<Events />}/>

    </Routes>     
  </div>
)
 
}

document.body.classList.add("darkmode")
export default App;
