
export function generateRequest(props){

    if(props.isFiltersApplied){

        return withFilters(props)

    }else{

        return withoutFilters(props)
    }
}

const withoutFilters = (props) =>{

    let options = {};
    let request = {};
    var countOptions = {};

    options.limit = props.rowsPerPage;
    if(props.sortOrder == 'asc'){

        options.sort = sort(props, 1)
        countOptions.sort = sort(props, 1)

    }else{

        options.sort = sort(props, -1)
        countOptions.sort = sort(props, -1)
    }

    options.skip = props.rowsPerPage * props.page;

    request.options = options;
    request.countOptions = countOptions;
    return request;
}

const withFilters = (props) =>{

    let options = {};
    let request = {};
    var countOptions = {};

    options.limit = props.rowsPerPage;
    if(props.sortOrder == 'asc'){

        options.sort = sort(props, 1)
        countOptions.sort = sort(props, 1)

    }else{

        options.sort = sort(props, -1)
        countOptions.sort = sort(props, -1)
    }

    options.skip = props.rowsPerPage * props.page;
    request.options = options;
    request.fields = props.queryFields;
    request.countOptions = countOptions;

    return request;

}

const sort = (props, value) =>{

    if(props.sortedColumn == '_id'){

        return {'_id': value}
    }
    if(props.sortedColumn == 'ID'){

        return {'ID': value}
    }
    if(props.sortedColumn == 'AccountID'){

        return {'AccountID': value}
    }
    if(props.sortedColumn == 'SiteID'){

        return {'SiteID': value}
    }
    if(props.sortedColumn == 'UUID'){

        return {'UUID': value}
    }
    if(props.sortedColumn == 'RecordNumber'){

        return {'RecordNumber': value}
    }
    if(props.sortedColumn == 'Category'){

        return {'Category': value}
    }
    if(props.sortedColumn == 'ComputerName'){

        return {'ComputerName': value}
    }
    if(props.sortedColumn == 'EventCode'){

        return {'EventCode': value}
    }
    if(props.sortedColumn == 'EventIdentifier'){

        return {'EventIdentifier': value}
    }
    if(props.sortedColumn == 'EventType'){

        return {'EventType': value}
    }
    if(props.sortedColumn == 'Logfile'){

        return {'Logfile': value}
    }
    if(props.sortedColumn == 'Message'){

        return {'Message': value}
    }
    if(props.sortedColumn == 'SourceName'){

        return {'SourceName': value}
    }
    if(props.sortedColumn == 'TimeGenerated'){

        return {'TimeGenerated': value}
    }
    if(props.sortedColumn == 'TimeWritten'){

        return {'TimeWritten': value}
    }
    if(props.sortedColumn == 'Type'){

        return {'Type': value}
    }
    if(props.sortedColumn == 'User'){

        return {'User': value}
    }
    if(props.sortedColumn == 'Timestamp'){

        return {'Timestamp': value}
    }
}

