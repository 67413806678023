
import React, {useEffect, useState } from 'react';
import "./assets/bootstrap/css/bootstrap.min.css";
import "./index.css";
import "./assets/custom/css/loader.css";
import "./assets/custom/css/authentication/auth_3.css";
import "./assets/custom/css/darkmode/global.css";
import "./assets/custom/css/structure.css";
import "./assets/custom/css/main.css";

import Loader from "./loader";
import { ACCESS_TOKEN } from './constants';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './util/APIUtil';
import { useCookies } from 'react-cookie'
import Resetpassword from './resetpassword';
import { changePassword } from './util/APIUtil';
import Header from './header';
import Breadcrumb from './Breadcrumb';

const ChangePassword = () =>{

    const [data,setData] = useState({
        logonId:'',
        password:'',
        confirmPassword:'',
        token:''
      })
    
      const [cookies, setCookie, removeCookie] = useCookies(ACCESS_TOKEN)
      const[loading, setLoading] = useState(true);
      const {logonId, password, confirmPassword} = data;
      const [lastError, setLastError] = useState('');
      const [error, setError] = useState('');

      let cookieToken = cookies.token;

      let [validateData, setValidateData] = useState({

        "logonId": "",
        "password": "",
        "confirmPassword": "",
        
    })


     let navigate = useNavigate(); 

      useEffect(()=>{
    
        getCurrentUser(cookieToken)
        .then(response => {
    
          if(response.status === 'error'){
           
            setLoading(false);
            navigate("/login");

          }
          if(response.status === 'success'){

            setLoading(false);
          }
          
          console.log(response);
          
        }).catch(error => {
          
            console.log(error);
            setLoading(false);
            navigate("/login");

        });
        
    }, [cookieToken, navigate])

    const handleResetPassword = () =>{

        navigate('/resetpassword')
    }

    const hangeChangePassword = () =>{
    
        if(data.logonId === ''){
           
            validateData = {};
            setValidateData({...validateData, "logonId":"Please enter logonId"})
            setLastError("logonId");

        }else if(data.password === ''){
            
            validateData = {};
            setValidateData({...validateData, "password":"Please enter password"})
            setLastError("password");

        }else if(data.confirmPassword === ''){
            
            validateData = {};
            setValidateData({...validateData, "confirmPassword":"Please enter confirm password"})
            setLastError("confirmPassword");

        }else if(data.password !== data.confirmPassword){
            
            validateData = {};
            setValidateData({...validateData, "confirmPassword":"Password and Conform Password are not matched!"})
            setLastError("confirmPassword");

        }else{

            validateData = {};
            setValidateData({...validateData, [lastError]:""})

            cookieToken = cookies.token;
            data.token = cookieToken;
            const changePasswordRequest = Object.assign({}, data);
            changePassword(changePasswordRequest, cookieToken)
            .then(response => {
    
                if(response.status === "SUCCESS"){
    
                    let expires = new Date()
                    expires.setTime(expires.getTime() + (14 * 24 * 60 * 60 * 1000))
                    setCookie(ACCESS_TOKEN, response.token, { path: '/',  expires})

                    setLoading(false);
                    localStorage.setItem("changepassword", "SUCCESS");
                    navigate("/")
                }

                if(response.status === "TOKEN_NOT_FOUND"){
                    
                    removeCookie(ACCESS_TOKEN,{path:'/'});    
                    setError(response.status);
        
                }

                if(response.status === "ERROR"){
    
                    removeCookie(ACCESS_TOKEN,{path:'/'});    
                    navigate("/login")
        
                }
    
                if(response.status === "LOGONID_NOT_FOUND"){
    
                    validateData = {};
                    setValidateData({...validateData, "logonId":"Please enter logonId"})
                    setLastError("logonId");
        
                }
    
            }).catch(error => {
                
                removeCookie(ACCESS_TOKEN,{path:'/'});    
                navigate("/login")

            });
        }
    }
    
     const changeHandler = e =>{
    
        setData({...data,[e.target.name]:e.target.value})
        
      }

      if(!loading){
        return (
            <> 
                {loading?<Loader />:null}   
                {error === 'TOKEN_NOT_FOUND'?<Resetpassword message={error}/>:<>
                <Header />
                
                    <div className="main-container" id="container">
                        <div id="content" className="main-content">
                            <Breadcrumb name={"Change Password"} url={"/changepassword"}/>
                        <div className="container-fluid login-three-container mt-4">
                            <div className="row main-login-three">
                                <div className="col-xl-5 col-lg-5 col-md-6">
                                    <div className="d-flex flex-column justify-content-between right-area widget">
                                        <div>
                                        <h2 className="text-align-center">Change Password</h2>
                                            <div className="login-three-inputs mt-5">
                                                <input type="text" placeholder="logonId" name="logonId" value={logonId} onChange={changeHandler}/>
                                                <i className="las la-user-alt"></i>
                                            {validateData.logonId === ''?null:<label style={{"color":"red"}}>{validateData.logonId}</label>}  
                                            </div>
                                            <div className="login-three-inputs mt-3">
                                                <input type="password" placeholder="Password" name="password" value={password} onChange={changeHandler}/>
                                                <i className="las la-lock"></i>
                                                {validateData.password === ''?null:<label style={{"color":"red"}}>{validateData.password}</label>}  
                                            </div>
                                            <div className="login-three-inputs mt-3">
                                                <input type="password" placeholder="confirm password" name="confirmPassword" value={confirmPassword} onChange={changeHandler}/>
                                                <i className="las la-lock"></i>
                                                {validateData.confirmPassword === ''?null:<label style={{"color":"red"}}>{validateData.confirmPassword}</label>}  
                                            </div>
                                            <div className="widget-footer mt-4">
                                                <button className="text-white btn bg-gradient-primary  mr-2" onClick={hangeChangePassword}>Change Password<i className="las la-key ml-2"></i></button>
                                                <a className="font-13 text-primary text-right" href='#' onClick={handleResetPassword} style={{"float": "right"}}>Recover Password</a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </>}           
                 
            </>
            
        )

    }else{

       return (
        <>
        <Loader />
        </>
       )
    }
    
}

export default ChangePassword