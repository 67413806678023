import React from 'react'

const DatatableLoader = () => {
  return (
    <>
        <div className="widget widget-content widget-content-area">
            <span className="spinner-border spinner-border-sm mr-1" role="status" aria-hidden="true"></span> Loading.....Please wait.
        </div>
    </>
  )
}

export default DatatableLoader
