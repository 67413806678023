import React, { useEffect } from 'react'
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/custom/css/main.css";
import "./assets/custom/css/structure.css";
import "./index.css";
import "./assets/custom/css/loader.css";
import "./assets/custom/css/authentication/auth_3.css";
import "./assets/custom/css/darkmode/global.css";
import { useState } from "react";
import { getCurrentUser } from './util/APIUtil';
import Loader from './loader.js';
import Header from './header';
import { useNavigate, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { ACCESS_TOKEN } from './constants';
import EventsData from './EventsData';

const Events = () => {
    const [cookies, setCookie] = useCookies(ACCESS_TOKEN)
    const [isLoaded, setLoaded] = useState(false);
    const[firstName, setFirstName] = useState("");
    const[lastName, setLastName] = useState("");

    const[fields, SetFields] = useState([]);
    const[sortColumn, setSortColumn] = useState('');
    const[sortOrder, setSortOrder] = useState('');

    let { chart } = useParams();
    let { value } = useParams();

    let navigate = useNavigate(); 
    let cookieToken = cookies.token;
    useEffect(()=>{
        getCurrentUser(cookieToken)
        .then(response => {
    
          if(response.status === 'error'){

            navigate("/login")

          }
          if(response.status === 'success'){
    
              setFirstName(response.firstName);
              setLastName(response.lastName);

              let values = []
              response.fields.forEach(element => {

                values.push({

                    value:element,
                    lable:element
                })

              });

              if(response.sortColumn !== null && response.sortOrder !== null){

                setSortColumn(response.sortColumn);
                setSortOrder(response.sortOrder);
              }else{

                setSortColumn('TimeWritten');
                setSortOrder('desc');
              }

              SetFields(values);
              setLoaded(true);
          }

          console.log(response);
          localStorage.removeItem("changepassword")
          
        }).catch(error => {
          
            console.log(error);
            setLoaded(true);
            navigate("/login")
        });
  }, [cookieToken, navigate])
  

    if(isLoaded){

        return (

            <div>
              <Header firstName={firstName} lastName={lastName}/>
              <EventsData fields={fields} sortColumn={sortColumn} sortOrder= {sortOrder} chart={chart} value={value}/>
            </div>
          )
    }else{

        return (

            <Loader />
        )
    }
}

export default Events
