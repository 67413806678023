import React, { useState, useRef } from 'react'

const Collapsible = (props) => {

const [isOpen, setOpen] = useState(false)

const parentRef = useRef();
  return (
    <div className='collapsible'>
      <button className='btn btn-primary toggle' onClick={() => setOpen(!isOpen)}>
        {props.label}
      </button>
      <div className="content-parent" ref={parentRef} style={
        isOpen? {
          height:120+props.height+"px"
        }:
       {

        height:"0px"
      }
      }>
          <div className='content'>
            {props.children}
          </div>
      </div>
    </div>
  )
}

export default Collapsible
