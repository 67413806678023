import React, { useState } from 'react'
import { useCookies } from 'react-cookie';
import { resetPassword } from './util/APIUtil';
import { ACCESS_TOKEN } from './constants';
import { useNavigate } from 'react-router-dom';

const Resetpassword = (props) => {

    const [errorMessage, setErrorMessage] = useState("");
    const [cookies] = useCookies(ACCESS_TOKEN);
    const [email, setEmail] = useState(false);

    const [message, setMessage] = useState(props.message)

    let navigate = useNavigate();

    const [data, setData] = useState({
        logonId: ""
    })

    let token = cookies.token;

    const handleResetPassword = () =>{

        setMessage("")

        if(data.logonId.trim() === ""){

            setErrorMessage("Please enter logonId!");
        
        }else{

            token = cookies.token;
            const resetPasswordRequest = Object.assign({}, data);
            resetPassword(resetPasswordRequest, token)
            .then(response => {
    
                if(response.status === "SUCCESS"){
    
                    setEmail(true);
                    setErrorMessage("");
                }
    
                if(response.status === "LOGONID_NOT_FOUND"){
    
                    setEmail(false);
                    setErrorMessage('LogonId is not found!');
                }
    
            }).catch(error => {
                
                setEmail(false);
                setErrorMessage(error.message);
                
            });

        }
    }

    const handleLogin = () =>{

        navigate('/login');
    }

    const changeHandler = e =>{

        setData({...data, [e.target.name]: e.target.value})
    }

    return (
    <>
    <div className="container-fluid login-three-container">
        <div id="load_screen" className="row main-login-three">
            <div className="col-xl-5 col-lg-5 col-md-6">
                <div className="d-flex flex-column justify-content-between h-100 right-area widget">
                    <div>
                    {email?<div><p className="text-success-teal font-17">Success!</p><h2 className="text-success-teal font-17">A email has been sent to <b>{data.logonId}</b>. Please check the email and click on the verification link to reset your password. </h2></div>:null}
                    {message === 'TOKEN_NOT_FOUND'?<h6 className="text-align-center" style={{"color":"red"}}>Invalid token or email, please try resetting your password again.</h6>:null}
                    <h2 className="text-align-center">Reset Password</h2>
                        <div className="login-three-inputs mt-5">
                            <input type="text" placeholder="logonId" name="logonId" value={data.logonId} onChange={changeHandler}/>
                            <i className="las la-user-alt"></i>
                            {errorMessage === ''?null:<label style={{"color":"red"}}>{errorMessage}</label>}  
                        </div>
                        <div className="widget-footer mt-4">
                            <button className="text-white btn bg-gradient-primary  mr-2" onClick={handleResetPassword}>Get Validation Code<i className="las la-key ml-2"></i></button>
                            <a className="font-13 text-primary text-right" href='/login' onClick={handleLogin} style={{"float": "right"}}>Go to login</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
    </>
  )
}

export default Resetpassword
