import React from "react";

import { components } from "react-select";
import Createable from "react-select/creatable";
import { SortableContainer, SortableElement } from "react-sortable-hoc";


  const customSelectStyles = {
  
    control: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",

    }),
    placeholder: (provided, state) => ({
      ...provided,
      color: "white"
    }),
    multiValue: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white",
      borderRadius:"3px"
    }),
    multiValueLabel: (provided, state) => ({
      ...provided,
      backgroundColor: "rgb(9, 113, 241)",
      color: "white"
    }),
    menu: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white",
      zIndex:999999999999999,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      backgroundColor: "#191919",
      color: "white"
    }),option: (provided, state) => ({
      ...provided,
      color: state.isFocused ? '#191919':"#ffffff",
    }),
  };
const SortableMultiValue = SortableElement((props) => {
  // this prevents the menu from being opened/closed when the user clicks
  // on a value to begin dragging it. ideally, detecting a click (instead of
  // a drag) would still focus the control and toggle the menu, but that
  // requires some magic with refs that are out of scope for this example
  const onMouseDown = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };
  const innerProps = { onMouseDown };
  return <components.MultiValue {...props} innerProps={innerProps} />;
});
const SortableSelect = SortableContainer(Createable);

export default function MultiSelectSort(props) {
 
  return (
    <SortableSelect
      // react-sortable-hoc props:
      axis="xy"
      onSortEnd={props.onSortEnd}
      distance={4}
      // small fix for https://github.com/clauderic/react-sortable-hoc/pull/352:
      getHelperDimensions={({ node }) => node.getBoundingClientRect()}
      // react-select props:
      isMulti
      options={props.options}
      value={props.selected}
      onChange={props.onChange}
      hideSelectedOptions={false}
      components={{
        MultiValue: SortableMultiValue
      }}
      menuShouldScrollIntoView={true}
      closeMenuOnSelect={false}
      styles={customSelectStyles}
      onMenuOpen={props.onMenuOpen}
      onMenuClose={props.onMenuClose}
    />
  );
}
