import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { piechart2 } from '../util/APIUtil'
import "../assets/custom/css/dashboard/dashboard_1.css"
import { useNavigate } from 'react-router-dom'

const PieChart2 = () => {

    const[options, setOptions] = useState({})

    const[series, setSeries] = useState([])

    const[isPiechartLoaded, setPiechartLoaded] = useState(false)


    let navigate = useNavigate();

    useEffect(() =>{


      if(localStorage.getItem('Piechart2')){

        const response = localStorage.getItem('Piechart2')
        let data = JSON.parse(response);
        let ids = [];
        let counts = [];
        if(data.length > 0){

            let i = 0;
            data.forEach(element => {

               if(i < 3){

                ids.push(element['_id'])
                counts.push(element['count'])
               }

                i++;

            })
        }    

        setSeries(counts);
        setOptions({
            chart: {
              width: 380,
              type: 'pie',
              animations: {
                enabled: false,
              },
              events: {
                dataPointSelection: (event, chartContext, config) => { 
                  console.log(config.w.config.labels[config.dataPointIndex])
                  navigate('/eventinfo/Piechart2/'+config.w.config.labels[config.dataPointIndex])
                }
              }
            },
            colors: ["#2262c6", "#5c1ac3", "#dc3545"],
            labels: ids,
            responsive: [{
              breakpoint: 480,
              options: {
                chart: {
                  width: 200
                },
                legend: {
                  position: 'bottom'
                }
              }
            }]
          })
        setPiechartLoaded(true);

      }else{
        piechart2().then(function (response) {
    

          localStorage.setItem('Piechart2', JSON.stringify(response))

          let ids = [];
          let counts = [];
          if(response.length > 0){

              let i = 0;
              response.forEach(element => {

                 if(i < 3){

                  ids.push(element['_id'])
                  counts.push(element['count'])
                 }

                  i++;

              })
          }    

          setSeries(counts);
          setOptions({
              chart: {
                width: 380,
                type: 'pie',
                events: {
                  dataPointSelection: (event, chartContext, config) => { 
                    console.log(config.w.config.labels[config.dataPointIndex])
                    navigate('/eventinfo/Piechart2/'+config.w.config.labels[config.dataPointIndex])
                  }
                }
              },
              colors: ["#2262c6", "#5c1ac3", "#dc3545"],
              labels: ids,
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            })
          setPiechartLoaded(true);
      });

      }

        
      }, [])
  return (
    <>
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
              <div className="widget-heading">
                  <h5 className="">Top 3 sites for errors</h5>
              </div>
              <div className="widget-content" style={{"position": "relative"}}>
              {isPiechartLoaded?<Chart options={options} series={series} type="pie" width={380}/>:null}
              </div>
          </div>
      </div>
    </>
  )
}

export default PieChart2
