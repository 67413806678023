import React from 'react'

const Breadcrumb = (props) => {
  return (
    <>
        <div className="sub-header-container">
            <header className="header navbar navbar-expand-sm">
                <a href="javascript:void(0);" className="sidebarCollapse" data-placement="bottom">
                <i className="las la-bars"></i>
                </a>
                <ul className="navbar-nav flex-row">
                    <li>
                        <div className="page-header">
                            <nav className="breadcrumb-one" aria-label="breadcrumb">
                                <ol className="breadcrumb">
                                    <li className="breadcrumb-item"><a href={props.url}>{props.name}</a></li>
                                    <li className="breadcrumb-item active" aria-current="page"><span>{props.name}</span></li>
                                </ol>
                            </nav>
                        </div>
                    </li>
                </ul>
            </header>
        </div>
    </>
  )
}

export default Breadcrumb
