import React, { useState } from 'react'
import MultiSelectSort from './MultiSelectSort'
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/custom/css/main.css";
import "./assets/custom/css/structure.css";
import "./index.css";
import "./assets/custom/css/loader.css";
import "./assets/custom/css/authentication/auth_3.css";
import "./assets/custom/css/darkmode/global.css";
import "./assets/custom/css/basic-ui/accordion.css"
import Collapsible from './Collapsible';

const CollapsibleFields = (props) => {

const [height, setHeight] = useState(0)

const onMenuOpen = () =>{

   setHeight(200)
}

const onMenuClose = () =>{

  setHeight(0)
}

  return (
    <Collapsible label="Fields" height={height}>
        <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="row">
                <div className="col-xs-4 col-sm-4">
                <label className='col-form-label text-center' style={{"color":"#ffffff"}}>Choose fields: </label>
                <MultiSelectSort options={props.options} selected={props.selected} onChange={props.onChange} onSortEnd={props.onSortEnd} onMenuClose={onMenuClose} onMenuOpen={onMenuOpen} height={height}/>
                </div>
                <div className="col-xs-2 col-sm-2">
                    <div className="col-xs-12 col-sm-12 mt-20">
                        <button type="button" className="btn btn-primary" onClick={props.handleApplyFields}>Apply Fields</button>
                    </div>
                    <div className="col-xs-12 col-sm-12 mt-2">
                        <button type="button" className="btn bg-gradient-success text-white" onClick={props.handleSaveFields}>Save Fields</button>
                    </div>
                </div>    
            </div>
        </div>
    </Collapsible>
  )
}

export default CollapsibleFields
