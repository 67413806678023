import React, { useState, useEffect } from 'react'
import { totalComputersCount } from '../util/APIUtil'

const TotalComputersCount = () => {

    const[isLoaded, setLoaded] = useState(false)
    const[count, setCount] = useState(0)

    useEffect(() =>{

        if(localStorage.getItem('TotalComputersCount')){

          const response = JSON.parse(localStorage.getItem('TotalComputersCount'))
          setCount(response.length);
          setLoaded(true);

        }else{
          totalComputersCount().then(function (response) {

            localStorage.setItem('TotalComputersCount', JSON.stringify(response))
            setCount(response.length);
            setLoaded(true);
          });

        }

        
      }, [])
  return (
    <>
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
              <div className="widget-heading">
                  <h5 className="">Total computers managed</h5>
              </div>
              <div className="widget-content" style={{"position": "relative"}}>
              {isLoaded?<label style={{'color':'white'}}> Total Computers : {count}</label>:null}
              </div>
          </div>
      </div>
    </>
  )
}

export default TotalComputersCount
