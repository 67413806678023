import React from 'react'

const Loader = () =>{

    return(

    <div id="load_screen">
        <div className="boxes">
            <div className="box">
                <div></div><div></div><div></div><div></div>
            </div>
            <div className="box">
                <div></div><div></div><div></div><div></div>
            </div>
            <div className="box">
                <div></div><div></div><div></div><div></div>
            </div>
            <div className="box">
                <div></div><div></div><div></div><div></div>
            </div>
        </div>
        <p className="xato-loader-heading">Xato</p>
    </div>
    )
}

export default Loader

