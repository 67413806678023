import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { tokenauth } from './util/APIUtil';
import { ACCESS_TOKEN } from './constants';
import { useCookies } from 'react-cookie';
import Resetpassword from './resetpassword';

const TokenAuth = () => {

  const [cookies, setCookie, removeCookie] = useCookies(ACCESS_TOKEN)
  const [error, setError] = useState("");
  
  let { token } = useParams();

  let navigate = useNavigate(); 
  

  useEffect(()=>{
    
    let tokenauthRequest = {

      "token" : token
    }
    tokenauth(tokenauthRequest, token)
    .then(response => {

      if(response.status === "TOKEN_NOT_FOUND"){
       
        removeCookie(ACCESS_TOKEN,{path:'/'});    
        setError(response.status)
        
      }
      if(response.status === 'SUCCESS'){

        let expires = new Date()
        expires.setTime(expires.getTime() + (14 * 24 * 60 * 60 * 1000))
        setCookie(ACCESS_TOKEN, token, { path: '/',  expires})
        navigate("/changepassword")
      }
      
      console.log(response);
      
    }).catch(error => {
      
        navigate("/login")
        console.log(error);

    });
    
}, [token, navigate, setCookie])

  return (
    <div>
     {error === 'TOKEN_NOT_FOUND'?<Resetpassword message={error}/>:null}
    </div>
  )
}

export default TokenAuth
