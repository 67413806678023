import React, { useEffect } from 'react';
import "./assets/bootstrap/css/bootstrap.min.css"
import "./assets/custom/css/main.css"
import "./assets/custom/css/structure.css"
import "./index.css"
import "./assets/custom/css/loader.css"
import "./assets/custom/css/authentication/auth_3.css"
import "./assets/custom/css/darkmode/global.css"
import "./loader.js"
import { useRef, useState } from "react";
import { getCurrentUser, saveDashboard } from './util/APIUtil';
import Loader from './loader.js';
import Header from './header';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie'
import { ACCESS_TOKEN } from './constants';
import Breadcrumb from './Breadcrumb';
import PieChart1 from './PieChart/PieChart1';
import PieChart2 from './PieChart/PieChart2';
import PieChart3 from './PieChart/PieChart3';
import BarChart1 from './PieChart/BarChart1';
import BarChart2 from './PieChart/BarChart2';
import TotalRows from './PieChart/TotalRows';
import TotalSitesCount from './PieChart/TotalSitesCount';
import TotalComputersCount from './PieChart/TotalComputersCount';
import { SortableContainer, SortableElement } from "react-sortable-hoc";
import arrayMove from 'array-move';


const Dashboard = () => {


    const Item = useRef();
    const [cookies, setCookie] = useCookies(ACCESS_TOKEN)
    const [isLoaded, setLoaded] = useState(false);
    const[firstName, setFirstName] = useState("");
    const[lastName, setLastName] = useState("");
    const[success, setSuccess] = useState('')
    const[error, setError] = useState('')

    const[successMessage, setSuccessMessage] = useState(localStorage.getItem("changepassword"))

    const SortableItem = SortableElement(({ value }) => (
        <>{value}</>
      ));
      
      const SortableList = SortableContainer(({ items }) => {
        return (
            <div className='row layout-top-spacing charts ml-1 mr-1'>
                {items.map((value, index) => (
                <SortableItem key={`item-${value.id}`} index={index} value={value.value} />
                ))}
            </div>
        );
      });


    let navigate = useNavigate(); 
    let cookieToken = cookies.token;

    const finalCharts = [
        {
          id: '1',
          classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
          value:<PieChart1 />
        },
        {
            id: '2',
            classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<PieChart2 />
        },
        {
            id: '3',
            classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<PieChart3 />
        },
        {
            id: '4',
            classs: 'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<BarChart1 />
        },
        {
            id: '5',
            classs: 'col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<BarChart2 />
        },
        {
            id: '6',
            classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<TotalRows />
        },
        {
            id: '7',
            classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<TotalSitesCount />
        },
        {
            id: '8',
            classs: 'col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing',
            value:<TotalComputersCount />
        },
      ]

      const onSortEnd = ({ oldIndex, newIndex }) => {
        updateCharts((prev) => arrayMove(prev, oldIndex, newIndex));

      };

    const [charts, updateCharts] = useState(finalCharts);

    const handleSaveDashboard = () => {

        setSuccess('')
        setError('')
        let data = [];
        charts.forEach(element => {

            data.push(element.id);
        })
        let dashboardRequest = {
            charts: data,
            token: cookieToken
        }
        saveDashboard(Object.assign({}, dashboardRequest), cookieToken).then(response =>{
  
            if(response.status === 'SUCCESS'){
      
              setSuccess("Saved fields successfully!")
      
            }else if(response.status === 'TOKEN_EXPIRED'){
      
              navigate("/login")
            }else{
      
              setError("Something went wrong, Please try again!")
            }
      
            console.log("success")
      
        }).catch(error =>{
    
        setError("Something went wrong, Please try again!")
        console.log(error.message)
    
        })
    }

    useEffect(()=>{
        setSuccess('')
        setError('')
        localStorage.removeItem('Piechart1')
        localStorage.removeItem('Piechart2')
        localStorage.removeItem('Piechart3')
        localStorage.removeItem('Barchart1')
        localStorage.removeItem('Barchart2')
        localStorage.removeItem('Totalrows')
        localStorage.removeItem('TotalSitesCount')
        localStorage.removeItem('TotalComputersCount')
        getCurrentUser(cookieToken)
        .then(response => {
    
          if(response.status === 'error'){

            navigate("/login")

          }
          if(response.status === 'success'){
    
              setFirstName(response.firstName);
              setLastName(response.lastName);

              const field1 = response.field1;
              let revisedCharts = [];
              if(field1 != null){
                const data = JSON.parse(field1);
                data.forEach(element => {

                    charts.forEach(elem => {
    
                        if(elem.id == element){
    
                            revisedCharts.push(elem)
                        }
    
                    });
    
                  });
    
                  if(revisedCharts.length){
                    updateCharts(revisedCharts);
                  }    
              }
              setLoaded(true);
          }

          console.log(response);
          localStorage.removeItem("changepassword")
          
        }).catch(error => {
          
            console.log(error);
            setLoaded(true);
            navigate("/login")
        });
  }, [cookieToken, navigate])
  
    if(isLoaded){

        return (

            <div>
                <Header firstName={firstName} lastName={lastName}/>
                <div className="main-container sidebar-closed sbar-open" id="container">
                    <div id="content" className="main-content">
                        <Breadcrumb name={"Dashboard"} url={"/"}/>
                        {successMessage === "SUCCESS"?<div><h2 className="text-align-center text-success-teal font-17 mt-5"> Successfully updated your password. </h2></div>:null}
                        {error?<label style={{"color":"red"}}>{error}</label>:null}<br/>
                        {success?<label style={{"color":"green"}}>{success}</label>:null}<br/>
                        <button type="button" className="btn btn-primary" onClick={handleSaveDashboard}>Save Dashboard</button>
                        {
                        charts?<SortableList axis="xy" items={charts} onSortEnd={onSortEnd} />:null}
                    </div>
                </div>
            </div>
            )
    }else{

        return (

            <Loader />
        )
    }
    

}

export default Dashboard