export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://realtimeanalysis.net/api';
export const BASE_URL = process.env.REACT_APP_BASE_URL || 'https://realtimeanalysis.net';
export const ACCESS_TOKEN = 'token';

export const FILTERS_URL = process.env.REACT_APP_FILTERS_URL || 'https://realtimeanalysis.net/events/filters'
export const TOTAL_ROWS_URL = process.env.REACT_APP_TOTAL_ROWS_URL || 'https://realtimeanalysis.net/events/totalrows'
export const EVENTS_URL = process.env.REACT_APP_EVENTS_URL || 'https://realtimeanalysis.net/events/events'
export const PIE_CHART1 = process.env.REACT_APP_PIE_CHART1 || 'https://realtimeanalysis.net/events/piechart1'
export const PIE_CHART2 = process.env.REACT_APP_PIE_CHART2 || 'https://realtimeanalysis.net/events/piechart2'
export const PIE_CHART3 = process.env.REACT_APP_PIE_CHART3 || 'https://realtimeanalysis.net/events/piechart3'

export const BAR_CHART1 = process.env.REACT_APP_BAR_CHART1 || 'https://realtimeanalysis.net/events/barchart1'
export const BAR_CHART2 = process.env.REACT_APP_BAR_CHART2 || 'https://realtimeanalysis.net/events/barchart2'

export const TOTAL_SITES_COUNT = process.env.REACT_APP_TOTAL_SITES_COUNT || 'https://realtimeanalysis.net/events/totalSites'
export const TOTAL_COMPUTERS_COUNT = process.env.REACT_APP_TOTAL_COMPUTERS_COUNT || 'https://realtimeanalysis.net/events/totalComputers'

export const DELETE_FILTERED_EVENTS_URL = process.env.REACT_APP_DELETE_FILTERED_EVENTS_URL || 'https://realtimeanalysis.net/events/deleteFilteredEvents'
