import React, { useState, useRef, useEffect } from 'react'
import { register } from './util/APIUtil';
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './util/APIUtil';
import Loader from './loader';

const Register = () => {


    const [isAuthenticated, setAuthenticated] = useState(false)
    const [loading, setLoading] = useState(true)
  
    const isMounted = useRef(false)
    useEffect(()=>{
  
        if(!isMounted.current){

            getCurrentUser()
            .then(response => {
        
              if(response.status === 'error'){
               
                setAuthenticated(false);
              }
              if(response.status === 'success'){
        
                 
                  setAuthenticated(true);
              }
              setLoading(false)
              console.log(response);
              
              
            }).catch(error => {
              
                console.log(error);
                setAuthenticated(false);
                setLoading(false)
            });

        }else{

            isMounted.current = true;
        }
  }, [])

let [validateData, setValidateData] = useState({

    "logonId": "",
    "password": "",
    "confirmPassword": "",
    "firstName": "",
    "lastName": "",
    "phoneNumber": "",
    "address1": "",
    "city": "",
    "province": "",
    "postalCode":"",
})

const [data, setData] = useState({

    "logonId": "",
    "password": "",
    "confirmPassword": "",
    "firstName": "",
    "lastName": "",
    "phoneNumber": "",
    "address1": "",
    "city": "",
    "province": "AB",
    "postalCode":"",
});

const [lastError, setLastError] = useState('');
const [error, setError] = useState('');

let navigate = useNavigate(); 
      const login = () =>{ 
        let path = `/login`; 
        navigate(path);
      }

let navigateDashboard = useNavigate(); 
    const dashboard = () =>{ 
        let path = `/`; 
        navigateDashboard(path);
}

const handleSubmit = () => {

    if(data.logonId === ''){

        validateData = {};
        setValidateData({...validateData, "logonId":"Please enter logonId"})
        setLastError("logonId");
    }else if(data.password.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "password":"Please enter password"})
        setLastError("password");
    }else if(data.confirmPassword.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "confirmPassword":"Please enter confirm password"})
        setLastError("confirmPassword");
    }else if(data.confirmPassword !== data.password){

        validateData = {};
        setValidateData({...validateData, "confirmPassword":"Password and Conform Password are not matched!"})
        setLastError("confirmPassword");

    }else if(data.firstName.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "firstName":"Please enter first name"})
        setLastError("firstName");

    }else if(data.lastName.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "lastName":"Please enter last name"})
        setLastError("lastName");

    }else if(data.phoneNumber.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "phoneNumber":"Please enter phone number"})
        setLastError("phoneNumber");

    }else if(data.address1.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "address1":"Please enter address1"})
        setLastError("address1");

    }else if(data.city.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "city":"Please enter city"})
        setLastError("city");

    }else if(data.province.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "province":"Please select province"})
        setLastError("province");

    }else if(data.postalCode.trim() === ''){

        validateData = {};
        setValidateData({...validateData, "postalCode":"Please enter postal code"})
        setLastError("postalCode");

    }else{
        validateData = {};
        setValidateData({...validateData, [lastError]:""})

        const registerRequest = Object.assign({}, data);
        register(registerRequest)
        .then(response => {


            setError("")
            if(response.status === 'LOGONID_ALREADY_EXIST'){

                validateData = {};
                setValidateData({...validateData, "logonId":"Logon Id is already exist, Please enter new one!"})
                setLastError("logonId");
                setError("");

            }else if(response.status === 'SUCCESS'){

                login();

            }else{
                
                validateData = {};
                setValidateData({...validateData, [lastError]:""})
                setError("Something went wrong, Please try again later!")

            }
            console.log(response);
           
        }).catch(error => {
            validateData = {};
            setValidateData({...validateData, [lastError]:""})
            setError(error.message)
            console.log(error);
        });
    }
}

const handleOnchange = (e) => {

     setData({...data, [e.target.name]:e.target.value});
}

if(isAuthenticated){

   return (
        <>
        {
            dashboard()
        }
        </>
   )
}else{

   if(!loading){

        return (
            <div>
                <div className=" widget-content widget-content-areacontainer-fluid login-three-container">
                    <div id="" className="row main-login-three" style={{"justifyContent": "center"}}>
                        <div className="col-xl-5 col-lg-5 col-md-6 mt-3">
                            <div className="d-flex flex-column justify-content-between  right-area widget">
                                <div>
                                    <h2 className="text-align-center">User Register</h2>
                                    {error === ''?null:<label style={{"color":"red"}}>{error}</label>}  
                                    <div className="login-three-inputs mt-5">
                                        <input type="email" name="logonId" id="logonId" placeholder="Enter Logon Id" onChange={handleOnchange}/>
                                        <i className="las la-envelope"></i>
                                        {validateData.logonId === ''?null:<label style={{"color":"red"}}>{validateData.logonId}</label>}  
                                    </div>
                                    <div className="login-three-inputs mt-3">
                                        <input type="password" name="password" id="password" placeholder="Enter password" onChange={handleOnchange}/>
                                        <i className="las la-lock"></i>
                                        {validateData.password === ''?null:<label style={{"color":"red"}}>{validateData.password}</label>}  
                                    </div>
                                    <div className="login-three-inputs mt-3">
                                        <input type="password" name="confirmPassword" id="confirmPassword" placeholder="Confirm password" onChange={handleOnchange}/>
                                        <i className="las la-lock"></i>
                                        {validateData.confirmPassword === ''?null:<label style={{"color":"red"}}>{validateData.confirmPassword}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                        <input type="text" name="firstName" id="firstName" placeholder="Enter First Name" onChange={handleOnchange}/>
                                        {validateData.firstName === ''?null:<label style={{"color":"red"}}>{validateData.firstName}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                        <input type="text" name="lastName" id="lastName" placeholder="Enter Last Name" onChange={handleOnchange}/>
                                        {validateData.lastName === ''?null:<label style={{"color":"red"}}>{validateData.lastName}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                        <input type="text" name="phoneNumber" id="phoneNumber" placeholder="Enter Phone number" onChange={handleOnchange}/>
                                        {validateData.phoneNumber === ''?null:<label style={{"color":"red"}}>{validateData.phoneNumber}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                        <input type="text" name="address1" id="address1" placeholder="Enter Address1" onChange={handleOnchange}/>
                                        {validateData.address1 === ''?null:<label style={{"color":"red"}}>{validateData.address1}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                        <input type="text" name="city" id="city" placeholder="Enter City Name" onChange={handleOnchange}/>
                                        {validateData.city === ''?null:<label style={{"color":"red"}}>{validateData.city}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4" data-select2-id="32">
                                        <select className="form-control  placeholder select2-hidden-accessible" name="province" id="province" placeholder="Select a country" data-select2-id="14" tabIndex="-1" aria-hidden="true" onChange={handleOnchange}>
                                            <option value="AB" data-select2-id="59" style={{"color": "black"}}>Alberta </option>
                                            <option value="BC" data-select2-id="47" >British Columbia </option>
                                            <option value="MB" data-select2-id="48">Manitoba </option>
                                            <option value="NB" data-select2-id="49">New Brunswick </option>
                                            <option value="NL" data-select2-id="50">Newfoundland and Labrador </option>
                                            <option value="NT" data-select2-id="51">Northwest Territories </option>
                                            <option value="NS" data-select2-id="52">Nova Scotia </option>
                                            <option value="NU" data-select2-id="53">Nunavut </option>
                                            <option value="ON" data-select2-id="54">Ontario </option>
                                            <option value="PE" data-select2-id="55">Prince Edward Island </option>
                                            <option value="QC" data-select2-id="56">Quebec </option>
                                            <option value="SK" data-select2-id="57">Saskatchewan </option>
                                            <option value="YT" data-select2-id="58">Yukon </option>
                                        </select>
                                        <span className="select2 select2-container mb-4 select2-container--default select2-container--below select2-container--focus" dir="ltr" data-select2-id="15" style={{"width": "346.678px"}}>
                                        <span className="selection"><span className="select2-selection select2-selection--single" aria-haspopup="true" aria-expanded="false" tabIndex="0" aria-labelledby="select2-i1j3-container">
                                        <span className="select2-selection__rendered" id="select2-i1j3-container" role="textbox" aria-readonly="true"></span>
                                        <span className="select2-selection__arrow" role="presentation"><b role="presentation"></b></span></span></span><span className="dropdown-wrapper" aria-hidden="true"></span></span>
                                        {validateData.province === ''?null:<label style={{"color":"red"}}>{validateData.province}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4" onChange={handleOnchange}>
                                        <input type="text" name="postalCode" id="postalCode" placeholder="Enter Postal code"/>
                                        {validateData.postalCode === ''?null:<label style={{"color":"red"}}>{validateData.postalCode}</label>}  
                                    </div>
                                    <div className="widget-footer text-right mt-4">
                                        <button type="reset" className="btn btn-primary mr-2" onClick={handleSubmit}>Submit</button>
                                        <button type="reset" className="btn btn-primary">Reset</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

   }else{

        return (

            <>
                <Loader />
            </>
        )
   }
}

  
}

export default Register
