import { API_BASE_URL, BAR_CHART1, BAR_CHART2, BASE_URL, DELETE_FILTERED_EVENTS_URL, EVENTS_URL, FILTERS_URL, PIE_CHART1, PIE_CHART2, PIE_CHART3, TOTAL_COMPUTERS_COUNT, TOTAL_ROWS_URL, TOTAL_SITES_COUNT } from '../constants';

const request = (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json',
    })
    
    if(options.token !== 'undefined' && options.token !== null && options.token !== undefined && options.token !== 'null') {
        headers.append('Authorization', 'Bearer ' + options.token)
    }

    const defaults = {headers: headers};
    options = Object.assign({}, defaults, options);

    return fetch(options.url, options)
    .then(response => 
        response.json().then(json => {
            if(!response.ok) {
                return Promise.reject(json);
            }
            return json;
        })
    );
};

const getCircularReplacer = () => {
    const seen = new WeakSet();
    return (key, value) => {
      if (typeof value === "object" && value !== null) {
        if (seen.has(value)) {
          return;
        }
        seen.add(value);
      }
      return value;
    };
  };

export function login(loginRequest, token) {
    return request({
        url: API_BASE_URL + "/login",
        method: 'POST',
        body: JSON.stringify(loginRequest),
        token: null
    });
}

export function resetPassword(resetPasswordRequest, token) {
    return request({
        url: API_BASE_URL + "/resetpassword",
        method: 'POST',
        body: JSON.stringify(resetPasswordRequest),
        token: null
    });
}

export function changePassword(changePasswordRequest, token) {
    return request({
        url: API_BASE_URL + "/changepassword",
        method: 'POST',
        body: JSON.stringify(changePasswordRequest),
        token: token
    });
}

export function tokenauth(tokenauthRequest, token) {
    return request({
        url: API_BASE_URL + "/tokenauth",
        method: 'POST',
        body: JSON.stringify(tokenauthRequest),
        token: token
    });
}

export function logout(token) {
    return request({
        url: BASE_URL + "/logout",
        method: 'POST',
        token: token
    });
}


export function register(signupRequest, token) {
    return request({
        url: API_BASE_URL + "/register",
        method: 'POST',
        body: JSON.stringify(signupRequest),
        token: token
    });
}

export function getCurrentUser(token) {
    if(!token) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: API_BASE_URL + "/userinfo",
        method: 'GET',
        token: token
    });
}

export function couchDBDocs(couchDBRequest, token){

    return request({
        
        url: API_BASE_URL + "/docs",
        method: 'POST',
        body: JSON.stringify(couchDBRequest),
        token: token
    })
}

export function couchDBTotalRecords(couchDBRequest, token){

    return request({
        
        url: API_BASE_URL + "/totalrecords",
        method: 'POST',
        body: JSON.stringify(couchDBRequest),
        token: token
    })
}

export function couchDBSortBy(couchDBRequest, token){

    return request({
        
        url: API_BASE_URL + "/sortBy",
        method: 'POST',
        body: JSON.stringify(couchDBRequest),
        token: token
    })
}

export function totalrows(){

    return request({
        
        url: TOTAL_ROWS_URL,
        method: 'GET',
        token: null
    })
}

export function totalSitesCount(){

    return request({
        
        url: TOTAL_SITES_COUNT,
        method: 'GET',
        token: null
    })
}

export function totalComputersCount(){

    return request({
        
        url: TOTAL_COMPUTERS_COUNT,
        method: 'GET',
        token: null
    })
}

export function filters(req){

    return request({
        
        url: FILTERS_URL,
        method: 'POST',
        body: JSON.stringify(req),
        token: null
    })
}

export function events(req){

    return request({
        
        url: EVENTS_URL,
        method: 'POST',
        body: JSON.stringify(req),
        token: null
    })
}

export function piechart1(){

    return request({

        url: PIE_CHART1,
        method:'GET',
        token: null
    })
}

export function piechart2(){

    return request({

        url: PIE_CHART2,
        method:'GET',
        token: null
    })
}

export function piechart3(){

    return request({

        url: PIE_CHART3,
        method:'GET',
        token: null
    })
}

export function barchart1(){

    return request({

        url: BAR_CHART1,
        method:'GET',
        token: null
    })
}

export function barchart2(){

    return request({

        url: BAR_CHART2,
        method:'GET',
        token: null
    })
}

export function saveFeilds(userFieldsRequest, token){

    return request({
        
        url: API_BASE_URL + "/savefields",
        method: 'POST',
        body: JSON.stringify(userFieldsRequest),
        token: token
    })
}

export function saveDashboard(dashboardRequest, token){

    return request({
        
        url: API_BASE_URL + "/savedashboard",
        method: 'POST',
        body: JSON.stringify(dashboardRequest, getCircularReplacer()),
        token: token
    })
}

export function deleteFiltered(req){

    return request({
        
        url: DELETE_FILTERED_EVENTS_URL,
        method: 'POST',
        body: JSON.stringify(req),
        token: null
    })
}
