import React, { useState, useEffect } from 'react'
import { totalSitesCount } from '../util/APIUtil'

const TotalSitesCount = () => {

    const[isLoaded, setLoaded] = useState(false)
    const[count, setCount] = useState(0)

    useEffect(() =>{

      if(localStorage.getItem('TotalSitesCount')){

          const response = JSON.parse(localStorage.getItem('TotalSitesCount'))
          setCount(response.length);
          setLoaded(true);

      }else{

        totalSitesCount().then(function (response) {

            localStorage.setItem('TotalSitesCount', JSON.stringify(response));
            setCount(response.length);
            setLoaded(true);
        });
      }
        
      }, [])
  return (
    <>
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
              <div className="widget-heading">
                  <h5 className="">Total sites managed</h5>
              </div>
              <div className="widget-content" style={{"position": "relative"}}>
              {isLoaded?<label style={{'color':'white'}}> Total Sites : {count}</label>:null}
              </div>
          </div>
      </div>
    </>
  )
}

export default TotalSitesCount
