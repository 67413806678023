
import React, {useEffect, useState } from 'react';
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/custom/css/main.css";
import "./assets/custom/css/structure.css";
import "./index.css";
import "./assets/custom/css/loader.css";
import "./assets/custom/css/authentication/auth_3.css";
import "./assets/custom/css/darkmode/global.css";
import Loader from "./loader";
import { ACCESS_TOKEN } from './constants';
import {login} from "./util/APIUtil"
import { useNavigate } from 'react-router-dom';
import { getCurrentUser } from './util/APIUtil';
import { useCookies } from 'react-cookie'


const Login = () =>{

    const [data,setData] = useState({
        logonId:'',
        password:'',
        rememberMe: false
      })
    
      const [cookies, setCookie] = useCookies(ACCESS_TOKEN)
      const[loading, setLoading] = useState(true);
      const {logonId, password, rememberMe} = data;
      const [logonIdMessage, setLogonIdMessage] = useState('');
      const [passwordMessage, setPasswordMessage] = useState('');
      let token = cookies.token;

     let navigateDashboard = useNavigate(); 

      useEffect(()=>{
    
        getCurrentUser(token)
        .then(response => {
    
          if(response.status === 'error'){
           
            setLoading(false);
          }
          if(response.status === 'success'){

              setLoading(false);
              navigateDashboard("/")
          }
          
          console.log(response);
          
        }).catch(error => {
          
            console.log(error);
            setLoading(false);

        });
        
    }, [token, navigateDashboard])

      let navigate = useNavigate(); 
      const register = () =>{ 
        let path = `/register`; 
        navigate(path);
      }

      const handleLogin = () =>{
    
        if(data.logonId === ''){
           
            setPasswordMessage('');
            setLogonIdMessage('Please enter logonId!');
            setLoading(false);

        }else if(data.password === ''){
            
            setLogonIdMessage('');
            setPasswordMessage('Please enter password!');
            setLoading(false);

        }else{

            token = cookies.token;
            const loginRequest = Object.assign({}, data);
            login(loginRequest, token)
            .then(response => {
    
                console.log(response)

                if(response.status === "SUCCESS"){
    
                    let expires = new Date()
                    expires.setTime(expires.getTime() + (14 * 24 * 60 * 60 * 1000))
                    setCookie(ACCESS_TOKEN, response.token, { path: '/',  expires})
                    setLoading(false);
                    navigateDashboard("/")
                }
    
                if(response.status === "LOGONID_NOT_FOUND"){
    
                    setPasswordMessage('');
                    setLogonIdMessage('LogonId is not found!');
                    setLoading(false);
                }
    
                if(response.status === "PASSWORD_WRONG"){

                    setLogonIdMessage('');
                    setPasswordMessage('Password is wrong!');
                    setLoading(false);
                }
    
            }).catch(error => {
                
                console.log(error.message)
            });
        }
    }
    
    const handleResetPassword = () =>{

        navigate("/resetpassword")

    }
     const changeHandler = e =>{
    
        setData({...data,[e.target.name]:e.target.value})
        
      }

      const handleKeyDown = e =>{
        if (e.key === 'Enter') {
            
            handleLogin();
          }
      }

      if(!loading){
        return (

            <div> 
                {loading?<Loader />:null}              
                <div className="container-fluid login-three-container mt-4">
                    <div id="load_screen" className="row main-login-three">
                        <div className="col-xl-5 col-lg-5 col-md-6">
                            <div className="d-flex flex-column justify-content-between h-100 right-area widget">
                                <div>
                                <h2 className="text-align-center">Login</h2>
                                    <div className="login-three-inputs mt-5">
                                        <input type="text" placeholder="logonId" name="logonId" value={logonId} onKeyDown={handleKeyDown} onChange={changeHandler}/>
                                        <i className="las la-user-alt"></i>
                                       {logonIdMessage === ''?null:<label style={{"color":"red"}}>{logonIdMessage}</label>}  
                                    </div>
                                    <div className="login-three-inputs mt-3">
                                        <input type="password" placeholder="Password" name="password" value={password} onKeyDown={handleKeyDown} onChange={changeHandler}/>
                                        <i className="las la-lock"></i>
                                        {passwordMessage === ''?null:<label style={{"color":"red"}}>{passwordMessage}</label>}  
                                    </div>
                                    <div className="login-three-inputs check mt-4">
                                    <input className="inp-cbx" id="cbx" type="checkbox" style={{"display": "none"}} value={rememberMe}/>
                                        <label className="cbx" htmlFor="cbx" onClick={() => setData({...data, "rememberMe":!rememberMe})}>
                                            <span>
                                                <svg width="12px" height="10px" viewBox="0 0 12 10">
                                                    <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                </svg>
                                            </span>
                                            <span className="font-13 text-muted">Remember me ?</span>
                                        </label>
                                    </div>
                                    <div className="widget-footer mt-4">
                                        <button className="text-white btn bg-gradient-primary  mr-2" onClick={handleLogin}>Login<i className="las la-key ml-2"></i></button>
                                        <a className="font-13 text-primary text-right" href='#' onClick={handleResetPassword} style={{"float": "right"}}>Recover Password</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> 
            </div>
            
        )

    }else{

       return (
        <>
        <Loader />
        </>
       )
    }
    
}

export default Login