import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { piechart1 } from '../util/APIUtil'
import "../assets/custom/css/dashboard/dashboard_1.css"
import { useNavigate } from 'react-router-dom'

const PieChart1 = () => {

    const[options, setOptions] = useState({})

    const[series, setSeries] = useState([])

    const[isPiechartLoaded, setPiechartLoaded] = useState(false)

    let navigate = useNavigate();

    useEffect(() =>{

        if(localStorage.getItem("Piechart1")){

            const response = localStorage.getItem("Piechart1")
            let data = JSON.parse(response);
            setPiechartLoaded(true)
            setSeries(data)
            setOptions({
                chart: {
                  width: 380,
                  type: 'pie',
                  animations: {
                    enabled: false,
                  },
                  events: {
                      dataPointSelection: (event, chartContext, config) => { 
                        console.log(config.w.config.labels[config.dataPointIndex])
                        navigate('/eventinfo/Piechart1/'+config.w.config.labels[config.dataPointIndex])
                      }
                  }
                },
                colors: ["#2262c6", "#5c1ac3", "#dc3545"],
                labels: ['Application', 'System', 'Security'],
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              })
        }else{

          piechart1().then(function (response) {
    
            localStorage.setItem("Piechart1", JSON.stringify(response));
            setPiechartLoaded(true)
            setSeries(response)
            setOptions({
                chart: {
                  width: 380,
                  type: 'pie',
                  events: {
                    dataPointSelection: (event, chartContext, config) => { 
                      console.log(config.w.config.labels[config.dataPointIndex])
                      navigate('/eventinfo/Piechart1/'+config.w.config.labels[config.dataPointIndex])
                    }
                  }
                },
                colors: ["#2262c6", "#5c1ac3", "#dc3545"],
                labels: ['Application', 'System', 'Security'],
                responsive: [{
                  breakpoint: 480,
                  options: {
                    chart: {
                      width: 200
                    },
                    legend: {
                      position: 'bottom'
                    }
                  }
                }]
              })
    
        });
        }

        
      }, [])
  return (
    <>
    <div className="col-xl-4 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
              <div className="widget-heading">
                  <h5 className="">Application, System and Security total messages</h5>
              </div>
              <div className="widget-content" style={{"position": "relative"}}>
              {isPiechartLoaded?<Chart options={options} series={series} type="pie" width={380}/>:null}
              </div>
          </div>
      </div>
    </>
  )
}

export default PieChart1
