import React, { useState, useEffect } from 'react'
import Chart from 'react-apexcharts'
import { useNavigate } from 'react-router-dom'
import { barchart2 } from '../util/APIUtil'

const BarChart2 = () => {

    const[options, setOptions] = useState({})

    const[series, setSeries] = useState([])

    const[isPiechartLoaded, setPiechartLoaded] = useState(false)

    let navigate = useNavigate();

    useEffect(() =>{

        if(localStorage.getItem('Barchart2')){

          const response = localStorage.getItem('Barchart2');
          let data = JSON.parse(response);
          let ids = [];
            let counts = [];
            if(data.length > 0){

                let i = 0;
                data.forEach(element => {

                   if(i < 5){

                    ids.push(element['_id'])
                    counts.push(element['count'])
                   }

                    i++;

                })
            }    

            setSeries([{
              name: 'Count',
              data: counts
            }]);
            setOptions({
              chart: {
                id: 'apexchart-example',
                animations:{
                  enabled: false,
                },
                events: {
                  dataPointSelection: (event, chartContext, config) => { 
                    console.log(config.w.config.xaxis.categories[config.dataPointIndex])
                    navigate('/eventinfo/Barchart2/'+config.w.config.xaxis.categories[config.dataPointIndex])
                  }
                }
              },
              xaxis: {
                categories: ids
              },
              colors: ['#2262c6']
            })
            setPiechartLoaded(true);

        }else{
          barchart2().then(function (response) {
    
            localStorage.setItem('Barchart2', JSON.stringify(response))
            let ids = [];
            let counts = [];
            if(response.length > 0){

                let i = 0;
                response.forEach(element => {

                   if(i < 5){

                    ids.push(element['_id'])
                    counts.push(element['count'])
                   }

                    i++;

                })
            }    

            setSeries([{
              name: 'Count',
              data: counts
            }]);
            setOptions({
              chart: {
                id: 'apexchart-example',
                events: {
                  dataPointSelection: (event, chartContext, config) => { 
                    console.log(config.w.config.xaxis.categories[config.dataPointIndex])
                    navigate('/eventinfo/Barchart2/'+config.w.config.xaxis.categories[config.dataPointIndex])
                  }
                }
              },
              xaxis: {
                categories: ids
              },
              colors: ['#2262c6']
            })
            setPiechartLoaded(true);
        });

        }

        
      }, [])
  return (
    <>
    <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 col-12 layout-spacing">
          <div className="widget widget-chart-two">
              <div className="widget-heading">
                  <h5 className="">Top 5 computers for warnings</h5>
              </div>
              <div className="widget-content" style={{"position": "relative"}}>
              {isPiechartLoaded?<Chart options={options} series={series} type="bar" width={700}/>:null}
              </div>
          </div>
      </div>
    </>
  )
}

export default BarChart2
