import React, { useRef, useEffect, useState } from 'react'
import "./assets/bootstrap/css/bootstrap.min.css";
import "./assets/custom/css/main.css";
import "./assets/custom/css/structure.css";
import "./index.css";
import "./assets/custom/css/loader.css";
import "./assets/custom/css/authentication/auth_3.css";
import "./assets/custom/css/darkmode/global.css";
import DataTable, { createTheme } from 'react-data-table-component'
import { ACCESS_TOKEN } from './constants'
import Breadcrumb from './Breadcrumb'
import DatatableLoader from './DatatableLoader';
import { ArrowDownward } from '@material-ui/icons';
import { filters, totalrows, saveFeilds, events, getCurrentUser, deleteFiltered } from './util/APIUtil';
import { useCookies } from 'react-cookie';
import { TablePagination, Tooltip } from '@mui/material';
import { useNavigate } from 'react-router';
import { generateRequest } from './Datatable/GenerateRequest';
import { makeStyles } from '@material-ui/core/styles';

import ChipInput from 'material-ui-chip-input'

import Moment from 'moment';

import CollapsibleFields from './CollapsibleFields';

//Sweet alert
import swal from 'sweetalert'

import {Icon, Menu, Dropdown} from 'antd'
import 'antd/dist/antd.css'



const EventsData = (props) => {

  const arrayMove = (array, from, to) => {
    array = array.slice();
    array.splice(to < 0 ? array.length + to : to, 0, array.splice(from, 1)[0]);
    return array;
  }

  const [isChartData, setChartData] = useState(props.chart?true:false);

  //Event Requset states
  const [isFiltersApplied, setFiltersApplied] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0);
  const [sortedColumn, setSortedColumn] = useState(props.sortColumn);
  const [sortOrder, setSortOrder] = useState(props.sortOrder);
  const [data, setData] = useState({
    docs: {},
    totalRows: 0
  })
  const [totalRows, setTotalRows] = useState(0)
  const [currentPage, setCurrentPage] = useState(0);
  const [queryFields, setQueryFields] = useState({});
  const [expandRows, setExpandRows] = useState(true);

  const filterMount = useRef(props.chart?false:true);
  const eventMount = useRef(props.chart?false:true);

  const filterDataMount = useRef(false);


  const [selected, setSelected] = useState([])
  const [loadUserFields, setLoadUserFields] = useState(true)

  const [menuFilters, setMenuFilters] = useState({})

  const[filterChips, setFilterChips] = useState([]);
  const[excludeFilterChips, setExcludeFilterChips] = useState([]);
  const[filterData, setFilterData] = useState([]);
  const[excludeFilterData, setExcludeFilterData] = useState([]);

  let selectionText = '';
 
  const onChange = (selectedOptions) => setSelected(selectedOptions);

  const[showMenu, setShowMenu] = useState(false)

  const onSortEnd = ({ oldIndex, newIndex }) => {
    const newValue = arrayMove(selected, oldIndex, newIndex);
    setSelected(newValue);
    console.log(
      "Values sorted:",
      newValue.map((i) => i.value)
    );
  };

  const handleApplyFields = () =>{

    setLoadUserFields(false);
    let values = []
    selected.forEach(element => {
        values.push({
          name: element.value,
          selector: row => row[element.value],
          sortable: true,
          cell: row => <Tooltip arrow={true} title={
            
            <div dangerouslySetInnerHTML={{__html: row.Message}}></div>
  
          }><div>{row[element.value]}</div></Tooltip>,
        },)
    });

    setColumns(values);
  }


  const Item = Menu.Item
  const menu = (columnName, value) => {

    return <Menu>
    <Item onClick={e => {

      if(columnName === 'Message' && selectionText){
        value = selectionText;
      }
      
      let val = {};
      val.include = true;
      val.columnName = columnName;
      val.value = value;
      setMenuFilters(val);
      
    }}>Include</Item>
    <Item onClick={e => {

      if(columnName === 'Message'){
        value = selectionText;
      }
      let val = {};
      val.include = false;
      val.columnName = columnName;
      val.value = value;
      setMenuFilters(val);

    }}>Exclude</Item>
  </Menu>

  }
    

  const render = value =>
    <Dropdown overlay={menu(value)} trigger={[`contextMenu`]}>
      <div>{value}</div>
    </Dropdown>

  const handleSaveFields = () =>{

    setLoadUserFields(false);
    setError("")
    setSuccess("")
    let values = []
    selected.forEach(element => {

      values.push(element.value)
    });
    
    if(values.length > 0){
      let columnsInfo = [];
      selected.forEach(element => {
        columnsInfo.push({
            name: element.value,
            selector: row => row[element.value],
            sortable: true,
            cell: row => <Tooltip arrow={true} title={
              
              <div dangerouslySetInnerHTML={{__html: row.Message}}></div>
    
            }><div>{row[element.value]}</div></Tooltip>,
          },)
      });
  
      setColumns(columnsInfo);
  
      setSuccess("")
      let cookieToken = cookies.token;
      saveFeilds(Object.assign({}, {"fields":values, "sortColumn":sortedColumn, "sortOrder":sortOrder}), cookieToken).then(response =>{
  
  
        if(response.status === 'SUCCESS'){
  
          setSuccess("Saved fields successfully!")
  
        }else if(response.status === 'TOKEN_EXPIRED'){
  
          navigate("/login")
        }else{
  
          setError("Something went wrong, Please try again!")
        }
  
        console.log("success")
  
      }).catch(error =>{
  
        setError("Something went wrong, Please try again!")
        console.log(error.message)
  
      })
  
      console.log(values);

    }else{

      setError("Please select at least one field.")
    }
  }

  const handleOnSort = (selectedColumn, sortDirection ) =>{

    console.log(selectedColumn.name, sortDirection)

    setSortedColumn(selectedColumn.name);
    setSortOrder(sortDirection);
    
 }

  Moment.locale('en');

  let navigate = useNavigate(); 
  const [cookies, setCookie] = useCookies(ACCESS_TOKEN)
 
    const sortIcon = <ArrowDownward />;

    createTheme('darktheme', {
      text: {
        primary: '#ffffff',
        secondary: '#ffffff',
      },
      background: {
        default: '#272727',
      },
      context: {
        background: '#cb4b16',
        text: '#FFFFFF',
      },
      divider: {
        default: '#000000',
      },
      striped: {
        default: "#191919",
        text: "#ffffff"
      },button: {
        default: "#ffffff",
        focus: "#404040",
        hover: "#4d4d4d",
        disabled: "gray",
      },highlightOnHover:{

        default:"#080808",
        text:"#ffffff"

      }
    });

    const customStyles = {
      rows: {
        style: {
          minHeight: '72px', // override the row height
        }
      },
      headCells: {
        style: {
          paddingLeft: '8px', // override the cell padding for head cells
          paddingRight: '8px',
          border: '1px solid #3a3a3a',
        },
      },
      cells: {
        style: {
          paddingLeft: '8px', // override the cell padding for data cells
          paddingRight: '8px',
          border: '1px solid #3a3a3a',
        },
      }
    };
    

    const [distinctFilters, setDistinctFilters] = useState({

      siteIds : [],
      computerNames : [],
      types : [],
      logfiles : []

    })

    const [progress, setProgress] = useState(false)
    const [selectors, setSelectors] = useState({

      SiteID: "",
      ComputerName: "",
      Type: "",
      Logfile: ""

    })


    const [options, setOptions] = useState([])


    const [defaultPage, setDefaultPage] = useState(false)


    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
  
    const [columns, setColumns] = useState([
      {
        name: 'Logfile',
        selector: row => row['Logfile'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('Logfile', row.Logfile)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.Logfile}</div></Tooltip></Dropdown>,
      },
      {
        name: 'Computer Name',
        selector: row => row['ComputerName'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('ComputerName', row.ComputerName)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.ComputerName}</div></Tooltip></Dropdown>,

      },
      {
        name: 'User',
        selector: row => row['User'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('User', row.User)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.User}</div></Tooltip></Dropdown>,

      },
      {
        name: 'Type',
        selector: row => row['Type'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('Type', row.Type)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.Type}</div></Tooltip></Dropdown>,

      },
      {
        name: 'Event Code',
        selector: row => row['EventCode'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('EventCode', row.EventCode)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.EventCode}</div></Tooltip></Dropdown>,
      },
      {
        name: 'Time Written',
        selector: row => row['TimeWritten'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('TimeWritten', row.Time_Written)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.TimeWritten}</div></Tooltip></Dropdown>,

      },
      {
        name: 'Event Identifier',
        selector: row => row['EventIdentifier'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('EventIdentifier', row.EventIdentifier)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.EventIdentifier}</div></Tooltip></Dropdown>,
      },
      {
        name: 'Event Type',
        selector: row => row['EventType'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('EventType', row.EventType)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.EventType}</div></Tooltip></Dropdown>,
      },
      {
        name: 'Site ID',
        selector: row => row['SiteID'],
        sortable: true,
        cell: row => <Dropdown overlay={menu('SiteID', row.SiteID)} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
          <div dangerouslySetInnerHTML={{__html: row.Message}}></div>

        }><div>{row.SiteID}</div></Tooltip></Dropdown>,
      }])

    const handleApplyFilter = () =>{

      setFiltersApplied(true)

      let fields = {};
      
      if(selectors.SiteID !== ''){

        fields['SiteID'] = selectors.SiteID;
        
      }

      if(selectors.ComputerName !== ''){
       
        fields['ComputerName'] = selectors.ComputerName;

      }

      if(selectors.Type !== ''){

        fields['Type'] = selectors.Type;

      }

      if(selectors.Logfile !== ''){

        fields['Logfile'] = selectors.Logfile;

      }
      
      setPage(0);
      setQueryFields(fields);
      setDefaultPage(true);

    }

    const handleClearFilter = () =>{

      setSelectors({

        SiteID: "",
        ComputerName: "",
        Type: "",
        Logfile: ""
  
      })
      setPage(0)
      setQueryFields({})
      setFiltersApplied(false)
      setDistinctFilters({

        siteIds : [],
        computerNames : [],
        types : [],
        logfiles : []
  
      })
    }

    const getAllFilters = () =>{


      let request = {
        queryFields : queryFields,
        isChartData : isChartData
      }

      filters(Object.assign({}, request)).then(response => {
          const data = response[0];

          let siteIds = distinctFilters.siteIds;
          let computerNames = distinctFilters.computerNames;
          let types = distinctFilters.types;
          let logfiles = distinctFilters.logfiles;

          if(data['siteIds']){

            siteIds = data['siteIds'];
          }

          if(data['computerNames']){

            computerNames = data['computerNames'];
          }

          if(data['types']){

            types = data['types'];
          }

          if(data['logfiles']){

            logfiles = data['logfiles'];
          }
          
          let allData = {

            siteIds : siteIds,
            computerNames : computerNames,
            types : types,
            logfiles : logfiles

          }

          setDistinctFilters(allData)

        }).catch(error => {
          // some error
        
          console.log(error)

        });
    }


    const getAllEvents = () =>{

      setProgress(true)
      let cookieToken = cookies.token;
    
      const params = {

        rowsPerPage : rowsPerPage,
        page : page,
        sortOrder : sortOrder,
        sortedColumn : sortedColumn,
        isFiltersApplied: isFiltersApplied,
        currentPage : currentPage,
        queryFields : queryFields,
        
      }      

      const eventRequest = generateRequest(params);

        events(Object.assign({}, eventRequest)).then(function (response) {
         
          let docs = [];
          let records =  [];
          let count = 0;
          if(Object.keys(response).length > 0){

            records = response.docs;
            count = response.count;

            let i = 0;
            let selectedFields = [];
  
            records.forEach(element => {
              let doc = element;
  
              let keys = []
              let values = [];
  
              if(i === 0){
  
                for(var key in doc){
  
                  if(key.toLowerCase() !== 'message'){
                    values.push({
                      value:key,
                      label:key
                    })
  
                    keys.push(key)
                  }
                }
  
                let info = values;
                setOptions(values)
                if(keys.length > 0 && loadUserFields){
                  props.fields.forEach(field => {
          
                    var i = keys.indexOf(field.value);
                    selectedFields.push(info[i])
          
                  });
          
                  setSelected(selectedFields)
                 
                }
                
              }
  
              i++;
  
              if(doc.Message !== null){
  
                let message = escapeHtmlMessage(doc.Message);
                doc.Message = message;
              }
              let timeWritten = doc.TimeWritten;
              doc.Time_Written = timeWritten;
              timeWritten = Moment(timeWritten).format("yyyy-MM-DD HH:mm:ss")
              doc.TimeWritten = timeWritten;
              docs.push(doc);
              
            });

            setData({
              docs: docs,
              totalRows: count
            });
            
            
            if(selectedFields.length > 0 && loadUserFields){
              let columnsInfo = []
              selectedFields.forEach(item => {
                columnsInfo.push({
                    name: item.value,
                    selector: row => row[item.value],
                    sortable: true,
                    cell: row => <Dropdown overlay={menu(item.value, (item.value === 'TimeWritten')?row['Time_Written']:row[item.value])} trigger={[`contextMenu`]}><Tooltip arrow={true} title={
                      
                      <div dangerouslySetInnerHTML={{__html: row.Message}}></div>
            
                    }><div>{row[item.value]}</div></Tooltip></Dropdown>,
                  },)
              });
              setColumns(columnsInfo);
            }
          }else{

            setData({
              docs: docs,
              totalRows: count
            });

          }

          setProgress(false)
          

        }).catch(function (error) {
          // some error
          setProgress(false)
          console.log(error.message)
        });

        setCurrentPage(page)
    }
    
    const ExpandedComponent = (row) =>{

      return (
        <>
            <p>UUID: {row.data.UUID}</p>
            Message:

            <Dropdown overlay={menu('Message', row.data.Message)} trigger={[`contextMenu`]}>
              <p dangerouslySetInnerHTML={{__html: row.data.Message}} onMouseUp={() => {

                var t = '';
                if (window.getSelection) {
                    t = window.getSelection();
                } else if (document.getSelection) {
                    t = document.getSelection();
                } else if (document.selection) {
                    t = document.selection.createRange().text;
                }

                selectionText = t.toString();
                //setSelectedText(t.toString());
                console.log(t.toString());
                
              }}></p>
            </Dropdown>

            
            
            <p>Record Number: {row.data.RecordNumber}</p>
            <p>Source Name: {row.data.SourceName}</p>
        </>
    );

    }

    const onRowClicked = (row, event) => { 
      
      console.log(row) 
    
    };

    const escapeHtmlMessage = (message) =>{

      let escapedMessage = message.replace("(?:\\.)+", "</b><br/>");
      escapedMessage = escapedMessage.replaceAll("b'", "<b style='color:#43beb2;'>");
      escapedMessage = escapedMessage.replaceAll("\\r\\n\\r\\n", "<br/>");
      escapedMessage = escapedMessage.replaceAll("\\r\\n\\t", "<br/>&ensp;");
      escapedMessage = escapedMessage.replaceAll("\\r\\n\\t\\t", "<br/>&ensp;&ensp;");
      escapedMessage = escapedMessage.replaceAll("\\r\\n", "<br/>&ensp;&ensp;");
      escapedMessage = escapedMessage.replaceAll("\\t\\t", "&ensp;&ensp;");
      escapedMessage = escapedMessage.replaceAll("\\t", "&ensp;");
      escapedMessage = escapedMessage.replaceAll("\)", ")<br/>");
      escapedMessage = escapedMessage.replaceAll(";", "<br/>");
      escapedMessage = escapedMessage.replaceAll("&ensp<br/>", "&ensp;");
      escapedMessage = escapedMessage.replaceAll("\:\.", "<br/>");
      return escapedMessage;
    }

    let cookieToken = cookies.token;

    const handleSelectFilter = e =>{

       console.log(e.currentTarget.id)
       console.log(e.target.value)

      if(e.target.value){
        let fields = {};
        Object.keys(selectors).map((e) => {
 
           if(selectors[e]){
 
             fields[e] = selectors[e];
           }
 
        });
        setSelectors({...selectors, [e.currentTarget.id]:e.target.value})
        fields[e.currentTarget.id] = e.target.value;
        setQueryFields(fields);
        setFiltersApplied(true)
      }

    }

    useEffect(() =>{

      console.log(menuFilters)
      if(Object.keys(menuFilters).length){
        const columnName = menuFilters.columnName;
        const value = menuFilters.value;
        if(menuFilters.include){

          if(!filterChips.includes(columnName+" : "+value)){
    
            if(!excludeFilterChips.includes(columnName+" : "+value)){

              setFilterChips(filterChips => [columnName+" : "+value, ...filterChips])
              setFilterData(filterData => [{

                [columnName]:value

              }, ...filterData])
            }else{

              setFilterChips(filterChips => [columnName+" : "+value, ...filterChips])
              setFilterData(filterData => [{

                [columnName]:value

              }, ...filterData])

              setExcludeFilterChips(excludeFilterChips.filter( c => c !== columnName+" : "+value));
              setExcludeFilterData(excludeFilterData.filter(
                c => c[columnName] !== value
              ))

              
            }

          }

        }else {
          
          if(!excludeFilterChips.includes(columnName+" : "+value)){
    
            if(!filterChips.includes(columnName+" : "+value)){

              setExcludeFilterChips(excludeFilterChips => [columnName+" : "+value, ...excludeFilterChips])
              setExcludeFilterData(excludeFilterData => [{

                [columnName]:value

              }, ...excludeFilterData])
            }else{

              setExcludeFilterChips(excludeFilterChips => [columnName+" : "+value, ...excludeFilterChips])
              setExcludeFilterData(excludeFilterData => [{

                [columnName]:value

              }, ...excludeFilterData])

              setFilterChips(filterChips.filter( c => c !== columnName+" : "+value));
              setFilterData(filterData.filter(
                c => c[columnName] !== value
              ))

            }
            
          }

        }
      }


    }, [menuFilters])
    useEffect(() =>{

      if(props.chart && props.value && !isFiltersApplied){

        if(props.chart === 'Piechart1'){

          setSelectors({...selectors, 'Logfile':props.value})
          let fields = {};
          fields['Logfile'] = props.value;
          setQueryFields(fields)
          setFiltersApplied(true)
        }

        if(props.chart === 'Piechart2'){

          selectors.SiteID = props.value;
          selectors.Type = 'Error';

          setSelectors(selectors)
          let fields = {};
          fields['Type'] = 'Error';
          fields['SiteID'] = props.value;
          setQueryFields(fields)
          setFiltersApplied(true)
        }
        if(props.chart === 'Piechart3'){

          selectors.SiteID = props.value;
          selectors.Type = 'Warning';

          setSelectors(selectors)
          let fields = {};
          fields['Type'] = 'Warning';
          fields['SiteID'] = props.value;
          setQueryFields(fields)
          setFiltersApplied(true)
        }
        if(props.chart === 'Barchart1'){

          selectors.ComputerName = props.value;
          selectors.Type = 'Error';

          setSelectors(selectors)
          let fields = {};
          fields['Type'] = 'Error';
          fields['ComputerName'] = props.value;
          setQueryFields(fields)
          setFiltersApplied(true)
        }

        if(props.chart === 'Barchart2'){

          selectors.ComputerName = props.value;
          selectors.Type = 'Warning';

          setSelectors(selectors)
          let fields = {};
          fields['Type'] = 'Warning';
          fields['ComputerName'] = props.value;
          setQueryFields(fields)
          setFiltersApplied(true)
        }

      }
    }, [])

    useEffect(() => {

      setError("")
      setSuccess("")
      if(filterMount.current){
        //getAllFilters()
      }
      filterMount.current = true;

    }, [queryFields]);

    useEffect(() =>{

      setProgress(true)
      getCurrentUser(cookieToken)
        .then(response => {
    
          if(response.status === 'error'){

            navigate("/login")

          }
          if(response.status === 'success'){
    
            if(eventMount.current){

              getAllEvents()
            }

            eventMount.current = true;
            
          }

          console.log(response);
          
        }).catch(error => {
          
            console.log(error);
            navigate("/login")
        });
        

    }, [sortedColumn, sortOrder, page, queryFields, defaultPage, rowsPerPage])

    useEffect(() =>{

      if(filterDataMount.current){

        if(!filterChips.length && !excludeFilterChips.length){

          applyFilters();
        }

      }

      filterDataMount.current = true;

    }, [filterData, excludeFilterData])
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    
    const handleChangeRowsPerPage = event => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    }

    const handleCollapseRows = () =>{

        setExpandRows(!expandRows)
    }

    const useStyles = makeStyles((theme) => ({
      selectRoot: {
        color: "black !important",
        backgroundColor: "white"
      },
      select: {
        backgroundColor:"white !important",
        color: "black !important",
        
      },
      menuItem: {
        color: "black !important"
      },
      leftIconButton: {
        "& .MuiButtonBase-root:not([disabled])": {
          color: "white"
        }
      },
      rightIconButton: {
        "& .MuiButtonBase-root:not([disabled])": {
          color: "white"
        }
      },
      actions: {
        "& .MuiButtonBase-root:not([disabled])": {
          color: "white"
        },"& .MuiButtonBase-root:is([disabled])": {
          color: "gray"
        }
      }
    }));
    const classes = useStyles();

  const handleDeleteChip = (chip, index) =>{

    console.log(chip)
    console.log(index)
    setFilterChips(filterChips.filter( c => c !== chip));
    if(chip.includes(':')){

      var columnName = chip.substr(0, chip.indexOf(' : ')); 
      var value = chip.substr(chip.indexOf(' : ')+3); 
      setFilterData(filterData.filter(
        c => c[columnName] !== value
      ))

      console.log(filterData)

    }
  }

  const handleDeleteExcludeChip = (chip, index) =>{

    console.log(chip)
    console.log(index)
    setExcludeFilterChips(excludeFilterChips.filter( c => c !== chip));
    if(chip.includes(':')){

      var columnName = chip.substr(0, chip.indexOf(' : ')); 
      var value = chip.substr(chip.indexOf(' : ')+3); 
      setExcludeFilterData(excludeFilterData.filter(
        c => c[columnName] !== value
      ))

      console.log(excludeFilterData)

    }
  }

  const applyFilters = () =>{

    let $and = [];

    if(filterData.length){
      filterData.forEach(element => {
      
        Object.keys(element).forEach(key => {
  
          console.log(element[key])
  
          if(key === 'Message'){
  
            let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            let rev = {};
            rev.Message = $regex;
            $and.push(rev);
  
          }else{
  
            $and.push(element);
          }
  
        })
  
      });
  
    }
    
    if(excludeFilterData.length){
      excludeFilterData.forEach(element => {
      
        Object.keys(element).forEach(key => {
  
          console.log(element[key])
  
          if(key === 'Message'){
  

            let $not = {};
            let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            $not.$not = $regex;
            let rev = {};
            rev[key] = $not;
            $and.push(rev);

            /*let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            let rev = {};
            rev.Message = $regex;
            excludeRequest.push(rev);*/
  
          }else{
  
            let $ne = {};
            $ne.$ne = element[key];
            let rev = {};
            rev[key] = $ne;
            $and.push(rev);

          }
  
        })
  
      });
  
    }
    

    if(!$and.length){

      setQueryFields({})
      setFiltersApplied(false)
    }else{

      let request = {};

      request.$and = $and;
  
      setFiltersApplied(true)
      setQueryFields(request)
      console.log(JSON.stringify(request))
      
    }

    /*reqs.$and = reqs;
  
    setFiltersApplied(true)
    setQueryFields(request)
    console.log(JSON.stringify(request))*/
    
  }

  const deleteFilters = () =>{

    let $and = [];

    if(filterData.length){
      filterData.forEach(element => {
      
        Object.keys(element).forEach(key => {
  
          console.log(element[key])
  
          if(key === 'Message'){
  
            let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            let rev = {};
            rev.Message = $regex;
            $and.push(rev);
  
          }else{
  
            $and.push(element);
          }
  
        })
  
      });
  
    }
    
    if(excludeFilterData.length){
      excludeFilterData.forEach(element => {
      
        Object.keys(element).forEach(key => {
  
          console.log(element[key])
  
          if(key === 'Message'){
  

            let $not = {};
            let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            $not.$not = $regex;
            let rev = {};
            rev[key] = $not;
            $and.push(rev);

            /*let $regex = {};
            $regex.$regex = ".*"+element[key]+".*";
            let rev = {};
            rev.Message = $regex;
            excludeRequest.push(rev);*/
  
          }else{
  
            let $ne = {};
            $ne.$ne = element[key];
            let rev = {};
            rev[key] = $ne;
            $and.push(rev);

          }
  
        })
  
      });
  
    }
    
    let request = {};
    request.$and = $and;


    const params = {

      rowsPerPage : rowsPerPage,
      page : page,
      sortOrder : sortOrder,
      sortedColumn : sortedColumn,
      isFiltersApplied: true,
      currentPage : currentPage,
      queryFields : request,
      
    }      

    const eventRequest = generateRequest(params);


    swal({
      title: "Warning!",
      text: "Are you sure want to delete!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {

        deleteFiltered(Object.assign({}, eventRequest)).then(function (response) {

          if(response.acknowledged){
    
            console.log(response.deletedCount);
            swal("Deleted "+response.deletedCount+" successfully!", {
              icon: "success",
            }).then((value) => {
              getAllEvents();
            });
          }else{

            swal("Something went wrong, Please try again later!", {
              icon: "error",
            });
          }
    
          console.log(response)
    
        }).catch(error =>{

          swal("Something went wrong, Please try again later!", {
            icon: "error",
          });

        });
      }
    });

    console.log(JSON.stringify(request))

  }

  return (

    <div>
     <div className="main-container" id="container">
        <div id="content" className="main-content">
        <Breadcrumb name={"Events"} url={"/eventinfo"}/>
         <div className="col-lg-12">
          {error?<label style={{"color":"red"}}>{error}</label>:null}<br/>
          {success?<label style={{"color":"green"}}>{success}</label>:null}
          {data.totalRows > 0?<button type="button" className="btn btn-primary mb-4" style={{padding:'10px 40px'}} onClick={handleCollapseRows}>{expandRows? 'Close All' : 'Open All'}</button>: null}
          <CollapsibleFields options={options} selected={selected} onChange={onChange} onSortEnd={onSortEnd} handleApplyFields={handleApplyFields} handleSaveFields={handleSaveFields}/>
        </div>
        <ChipInput 
         //readOnly
         className='include-chip-input mt-2'
         onDelete={(chip, index) => handleDeleteChip(chip, index)}
         value={filterChips}
         />
         <br/>
         <ChipInput 
         //readOnly
         className='exclude-chip-input mt-2 mb-2'
         onDelete={(chip, index) => handleDeleteExcludeChip(chip, index)}
         value={excludeFilterChips}
         />
         <br/>
         {(filterChips.length || excludeFilterChips.length)?<><button type='button' className='btn btn-primary mr-2' onClick={applyFilters}>Apply Filters</button>
         <button type='button' className='btn btn-danger' onClick={deleteFilters}>Delete Filters</button></>:null}
        {progress?null: <TablePagination
        showFirstButton={true}
        showLastButton={true}
        component="div"
        count={data.totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        classes={{
          selectRoot: classes.selectRoot,
          selectIcon: classes.selectRoot,
          input: classes.selectRoot,
          select: classes.select,
          menuItem: classes.menuItem,
          actions: classes.actions
        }}
        backIconButtonProps={{ className: classes.leftIconButton }}
        nextIconButtonProps={{ className: classes.rightIconButton }}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />}
        <DataTable
        title="Events"
        className="dataTables_wrapper"
        columns={columns}
        data={data.docs}
        highlightOnHover
        pagination = {false}
        paginationServer
        paginationTotalRows={data.totalRows}
        paginationPerPage={rowsPerPage}
        defaultSortAsc={props.sortOrder === 'asc'? true: false}
        defaultSortFieldId={props.sortColumn}
        sortServer = {true}
        sortIcon={sortIcon}
        expandableRows={true}
        expandableRowsComponent={ExpandedComponent}
        expandOnRowClicked={true}
        expandableRowExpanded={row => expandRows}
        onRowClicked={onRowClicked}
        paginationComponentOptions={{
            noRowsPerPage: true
        }}
        progressPending={progress}
        progressComponent = {<DatatableLoader />}
        onChangePage={page => setPage(page)}
        responsive={true}
        
        customStyles={customStyles}
        striped={true}
        fixedHeader={true}
        theme="darktheme"
        paginationDefaultPage={1}
        paginationResetDefaultPage = {defaultPage}
        onSort = {handleOnSort}
        />
        {progress?null: <TablePagination
        showFirstButton={true}
        showLastButton={true}
        component="div"
        count={data.totalRows}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        classes={{
          selectRoot: classes.selectRoot,
          selectIcon: classes.selectRoot,
          input: classes.selectRoot,
          select: classes.select,
          menuItem: classes.menuItem,
          actions: classes.actions
        }}
        backIconButtonProps={{ className: classes.leftIconButton }}
        nextIconButtonProps={{ className: classes.rightIconButton }}
        rowsPerPageOptions={[10, 15, 20, 25]}
        onRowsPerPageChange={handleChangeRowsPerPage}

      />}
      </div>
    </div>
    </div>
  )
}

export default EventsData
